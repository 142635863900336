import { createWebHistory, createRouter } from 'vue-router'

const Home = () => import (/*webpackChunkName: "Home"*/ '../views/Home')
const PrivacyPolicy = () => import (/*webpackChunkName: "PrivacyPolicy"*/ '../views/PrivacyPolicy')
const Terms = () => import (/*webpackChunkName: "Terms"*/ '../views/Terms')
const ContactUs = () => import (/*webpackChunkName: "ContactUs"*/ '../views/ContactUs')
const ThankYou = () => import (/*webpackChunkName: "ThankYou"*/ '../views/ThankYou')
const PageNotFound = () => import (/*webpackChunkName: "PageNotFound"*/ '../views/PageNotFound')

//TODO: Setup routes
const routes = [
  {
    path: '/',
    name: "ClosedCaseLeads",
    component: Home,
    meta: { title: 'Closed Case Leads' }
  },
  {
    path: '/privacy-policy',
    name: "Privacy Policy",
    component: PrivacyPolicy,
    meta: { title: 'Privacy Policy' }
  },
  {
    path: '/terms-of-use',
    name: "Terms of Use",
    component: Terms,
    meta: { title: 'Terms of Use' }
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: ContactUs,
    meta: { title: 'Contact Us' }
  },
  {
    path: '/thank-you',
    name: 'Thank You',
    component: ThankYou,
    meta: { title: 'Thank You' }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page Not Found',
    component: PageNotFound,
    meta: { title: 'Page Not Found ' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
