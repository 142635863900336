import { createStore } from 'vuex'
import { EMAIL_REGEX } from '../js/constants'
import { scrollToFirstError } from '../js/utils'
import router from '../routes'
// import axios from 'axios'

export default createStore({
  state: {
    fields: {},
    isLoading: false,
    validations: {
      firstAndLastNameError: false,
      companyNameError: false,
      websiteError: false,
      emailError: false,
      phoneError: false,
      practiceAreaError: false,
      stateError: false,
      firmSizeError: false
    }
  },
  getters: {
    getError: state => (validator) => state.validations[validator]
  },
  mutations: {
    SET_FIELD(state, { field, value }) {
      state.fields[field] = value
    },
    SET_IS_LOADING(state, status) {
      state.isLoading = status
    },
    RESET_VALIDATION_ERROR_TO_FALSE(state, { validator }) {
      state.validations[validator] = false
    },
  },
  actions: {
    async VALIDATE_ALL({dispatch, state}) {
      state.isLoading = true
      await Promise.all([
        dispatch('VALIDATE_NAME_INPUT'),
        dispatch('VALIDATE_COMPANY_NAME_INPUT'),
        dispatch('VALIDATE_WEBSITE_INPUT'),
        dispatch('VALIDATE_EMAIL_INPUT'),
        dispatch('VALIDATE_PHONE_INPUT'),
        dispatch('VALIDATE_PRACTICE_AREA_INPUT'),
        dispatch('VALIDATE_STATE_SELECT'),
        dispatch('VALIDATE_FIRM_SIZE_SELECT')
      ])

      const errorValue = Object.entries(state.validations)
      const checkIfNoErrors = errorValue.every(validation => validation[1] === false)

      if (checkIfNoErrors) {
        //no error handling
        dispatch('POST_DATA_TO_EVER_FLOW')
        await router.push({path: 'thank-you'})
      } else {
        //error handling
        scrollToFirstError()
        state.isLoading = false
      }
    },
    VALIDATE_NAME_INPUT({state, rootState}) {
      state.validations.firstAndLastNameError = !rootState.fields.first_and_last_name
    },
    VALIDATE_COMPANY_NAME_INPUT({state, rootState}) {
      state.validations.companyNameError = !rootState.fields.company_name
    },
    VALIDATE_WEBSITE_INPUT({state, rootState}) {
      state.validations.websiteError = !rootState.fields.website
    },
    VALIDATE_EMAIL_INPUT({state, rootState}) {
      if (!rootState.fields.email) {
        state.validations.emailError = true
        return
      }
      const validFormat = EMAIL_REGEX.test(String(rootState.fields.email).toLowerCase())
      if (!validFormat) state.validations.emailError = true
    },
    VALIDATE_PHONE_INPUT({state, rootState}) {
      if (!rootState.fields.phone || rootState.fields.phone.length < 10) state.validations.phoneError = true
    },
    VALIDATE_PRACTICE_AREA_INPUT({state, rootState}) {
      state.validations.practiceAreaError = !rootState.fields.practice_area
    },
    VALIDATE_STATE_SELECT({state, rootState}) {
      if (!rootState.fields.state || rootState.fields.state === 'Select state') state.validations.stateError = true
    },
    VALIDATE_FIRM_SIZE_SELECT({state, rootState}) {
      if (!rootState.fields.state || rootState.fields.state === 'Select firm size') state.validations.firmSizeError = true
    },
    POST_DATA_TO_EVER_FLOW({ rootState }) {
      const fieldsSaved = rootState.fields
      const data = {
        ...fieldsSaved
      }

      console.log('PostData: ', data)
      // axios.post(url, data)
    }
  },
  modules: {
  }
})
