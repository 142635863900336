export const scrollToFirstError = () => {
  const el = document.querySelector('.error')

  if (!el || isInViewport(el)) return

  window.scrollTo({
    top: el.offsetTop - 100,
    behavior: "smooth"
  })
}

const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 80 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}