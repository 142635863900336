import { createApp } from 'vue'
import '@/assets/styles/index.scss'
import router from "@/routes"
import App from './App.vue'
import store from './store'
import Maska from 'maska'

createApp(App)
  .use(router)
  .use(store)
  .use(Maska)
  .mount('#app')
