<script>
export default {
  name: "Footer"
}
</script>

<template>
  <div class="footer">
    <div class="footer-container">
      <div class="wrapper">
        <div class="logo">
        </div>

        <div class="footer-nav">
          <router-link class="nav-button" title="Privacy Policy" to="/privacy-policy" target="_blank">Privacy Policy</router-link>
          <router-link class="nav-button" title="Terms of Use" to="/terms-of-use" target="_blank">Terms of Use</router-link>
        </div>
      </div>

      <div class="copyright-text">
        Copyright © 2022 Closed Case Leads | All rights reserved.
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.logo {
  background-image: url("../../assets/img/logo_dark_small.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 60px;
}
</style>