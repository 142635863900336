<script>
export default {
  name: "Header"
}
</script>

<template>
  <div class="header">
    <div class="header-container">
      <div class="wrapper">
        <a href="/" class="logo"></a>
      </div>
      <a href="https://inbounds.everflowclient.io/affiliate/signup" class="call-to-action">
        Get started
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.logo {
  background-image: url("../../assets/img/logo-header.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 195px;
  width: 100%;
  height: 70px;
}
.call-to-action {
  max-width: 320px;
  padding: 10px;
  font-family: "Open Sans", open, sans-serif;
  font-weight: bold;
  color: #f6f6f4;
  background-color: #0f8bdb;
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    background-color: #12a2ff;
  }
  &:active {
    background-color: #0089de;
  }
}

@media (max-width: 400px) {
  .logo {
    min-width: 150px;
  }
  .call-to-action {
    padding: 5px;
    font-size: 15px;
  }
}
</style>