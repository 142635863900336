<script setup>
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import Header from './components/shared/Header'
import Footer from './components/shared/Footer'

const currentRoute = useRoute()
watch(currentRoute, () => {
  if ('title' in currentRoute.meta) {
    document.title = currentRoute.meta.title
  }
})
</script>

<template>
  <Header/>
  <div class="hero">
    <router-view></router-view>
  </div>
  <Footer/>
</template>